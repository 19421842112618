import React, { useState } from "react";

function ContactForm() {
  const [message, setMessage] = useState("");
  const [nombre, setNombre] = useState("");
  const sendWhatsAppMessage = () => {
    const phone = "+523339027092"; // Include country code
    const completeMessage = `Hola, te contacto desde el sitio de MaikaTeatro, mi nombre es ${nombre}. \n... ${message}`;
    const url = `whatsapp://send?text=${completeMessage}&phone=${phone}`;

    window.open(url);
  };

  return (
    <div
      className="col-md-6 footer2 wow bounceInUp"
      data-wow-delay=".25s"
      id="contact"
    >
      <form action="correo.php" method="POST">
        <div className="form-box">
          <h4>CONTACTANOS</h4>
          <table className="table table-responsive d-table">
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="form-control pl-0"
                    placeholder="NOMBRE"
                    name="nombre"
                    onChange={(e) => setNombre(e.target.value)}
                    required
                  />
                </td>
                <td>
                  <input
                    type="email"
                    className="form-control pl-0"
                    placeholder="EMAIL"
                    name="email"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2"></td>
              </tr>
              <tr>
                <td colSpan="2"></td>
              </tr>
              <tr>
                <td colSpan="2">
                  <input
                    type="text"
                    className="form-control pl-0"
                    placeholder="MENSAJE"
                    name="mensaje"
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2"></td>
              </tr>
              <tr>
                <td className="text-center pl-0">
                  <button type="submit" className="btn btn-dark">
                    Enviar Email
                  </button>
                </td>
                <td className="text-center pl-0">
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={sendWhatsAppMessage}
                  >
                    Enviar WhatsApp
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;

export const talleres = [
    {
        'id': 'workshop_thumb_01',
        'url': '/images/workshop_thumb_01.jpg'
    },
    {
        'id': 'workshop_thumb_02',
        'url': '/images/workshop_thumb_02.jpg'
    },
    {
        'id': 'workshop_thumb_03',
        'url': '/images/workshop_thumb_03.jpg'
    },
    {
        'id': 'workshop_thumb_04',
        'url': '/images/workshop_thumb_04.jpg'
    },
    {
        'id': 'workshop_thumb_05',
        'url': '/images/workshop_thumb_05.jpg'
    },
    {
        'id': 'workshop_thumb_06',
        'url': '/images/workshop_thumb_06.jpg'
    }
]
export const formacion = [
    {
        'year': 2020,
        'project':'Programa de formación para nuevos titiriteros en Durango'
    },
    {
        'year': 2009,
        'project':'Recetario para cocinar muñecos animados'
    },
    {
        'year': 2008,
        'project':'Acercamiento al trabajo de títeres'
    }
]

import React from "react";

const NotaCultura = ({ credits }) => {
  const url =
    "https://www.gob.mx/cultura/prensa/maika-teatro-compania-duranguense-dedicada-a-la-investigacion-ejecucion-y-difusion-del-teatro-de-titeres-y-objetos?idiom=es&fbclid=IwAR2ulu-RnkeWethflXZ6yjmmdT1q_ly995hCb_vnLAOgsxB0SAc8SR7mlUg";
  return (
    <>
      <h2 className="wow" id="cultura">
        Secretaría de Cultura México
      </h2>
      <hr />
      <div className="col-md-12">
        <div className="paikam-press-card">
          <div className="card-body">
            <h5 className="paikam-press-card-title">
              En enero del 2024 la Secretaría de Cultura del Gobierno de México
              publicó una comunicado de prensa en su sitio oficial donde resume
              la historia de Maika Teatro y sus mas de 15 años de trabajo.
            </h5>
            <a
              href={url}
              className="paikam-press-card-link"
              target="_blank"
              rel="noreferrer"
            >
              leer comunicado
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotaCultura;
